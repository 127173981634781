import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  playerReleaseId: null,
  trackList: [],
  currentIndex: null,
  isPlaying: false,
  audioProgress: 0,
  playerDisplay: false,
};

const playerSlice = createSlice({
  name: 'player',
  initialState,
  reducers: {
    setPlayerReleaseId(state, action) {
      state.playerReleaseId = action.payload;
    },
    setTrackList(state, action) {
      state.trackList = action.payload;
    },
    setCurrentIndex(state, action) {
      state.currentIndex = action.payload;
    },
    setIsPlaying(state, action) {
      state.isPlaying = action.payload;
    },
    setAudioProgress(state, action) {
      state.audioProgress = action.payload;
    },
    setPlayerDisplay(state, action) {
      state.playerDisplay = action.payload;
    },
  }
});

export const { setPlayerReleaseId, setTrackList, setCurrentIndex, setIsPlaying, setAudioProgress, setPlayerDisplay } = playerSlice.actions;
export default playerSlice.reducer;
