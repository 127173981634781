import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
  apiKey: "AIzaSyCEgOYucZbjHyOxW-qcO__F0t4_M6e_PV8",
  authDomain: "acid-discos.firebaseapp.com",
  projectId: "acid-discos",
  storageBucket: "acid-discos.appspot.com",
  messagingSenderId: "44518038391",
  appId: "1:44518038391:web:881a001c8e5a388a149f72",
};

// Initialise Firebase
const app = initializeApp(firebaseConfig);

// Inicializa y exporta los servicios que vas a utilizar
const auth = getAuth(app);
const db = getFirestore(app); // Inicializa Firestore y exporta
const storage = getStorage(app)

export {app, auth, db, storage}