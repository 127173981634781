import React from "react";

function BandcampBanner() {
  return (
    <div className="w-full text-white text-center text-4xl py-80 min-h-[20vh]" style={{ fontFamily: 'Bootzy Condensed TM Regular, sans-serif', backgroundColor:'#0f0f0f' }}>
      <span className="uppercase whitespace-pre-line tracking-wide leading-none">you can also find us and buy our{'\n'}records in bandcamp{' '}
      <a
      href="https://mmdiscos.bandcamp.com/"
      className="text-white underline"
      target="_blank"
      rel="noopener noreferrer"
      >
        here
      </a>
      </span>
    </div>
  )
}

export default BandcampBanner;