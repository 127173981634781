import React from 'react'

function Footer() {
  return (
    <footer className='relative z-20'>
      <div className='w-full flex justify-between sm:text-base lg:text-xl px-4 lg:px-6'>
        <a href="https://mmdiscos.bandcamp.com/" target='_blank' rel="noreferrer" className="leading-6 text-white">
          bandcamp
        </a>
        <a href="https://soundcloud.com/mmdiscos" target='_blank' rel="noreferrer" className="leading-6 text-white">
          soundcloud
        </a>
        <a href="https://www.instagram.com/mm.discos/" target='_blank' rel="noreferrer" className="leading-6 text-white">
          instagram
        </a>
      </div>
  
      <div className='text-white text-center text-sm py-6'>
        <p>© 2024 Acid Discos. A sublabel of MM Discos.</p>
        <div className="flex justify-center space-x-2">
          <p>All rights reserved</p>
          <a href="https://www.freeprivacypolicy.com/live/488c9503-b2ab-4569-8750-1f0b0728e32a" 
            target="_blank" 
            rel="noopener noreferrer">
            Privacy Policy
          </a>
        </div>
      </div>
    </footer>
    
  )
}

export default Footer