import { createSlice } from '@reduxjs/toolkit';

const layoutSlice = createSlice({
  name: 'layout',
  initialState: {
    contactDisplay: false,
    menuReleasestDisplay: false,
  },
  reducers: {
    setContactDisplay(state, action) {
      state.contactDisplay = action.payload;
    },
    setMenuReleasestDisplay(state, action) {
      state.menuReleasestDisplay = action.payload;
    },
  },
});

export const { setContactDisplay, setMenuReleasestDisplay } = layoutSlice.actions;
export default layoutSlice.reducer;
