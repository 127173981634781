import { storage } from '../../firebaseConfig';
import { ref as storageRef, getDownloadURL } from 'firebase/storage';

export const getFirebaseDownloadURL = async (url) => {
  try {
    const storageReference = storageRef(storage, url);
    const downloadUrl = await getDownloadURL(storageReference);
    return downloadUrl;
  } catch (error) {
    throw new Error(`Failed to get download URL: ${error.message}`);
  }
};