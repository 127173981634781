import emailjs from '@emailjs/browser';

export const sendEmail = async (name, email, object, subject, url) => {
  const templateParams = {
    name,
    email,
    object,
    subject,
    url,
  };

  try {
    await emailjs.send(
      'service_zy7reyh',    // Service ID
      'template_d2nlc0h',   // Template ID
      templateParams,
      'axFVuMPP0boi1uZlf'   // User ID - API KEY PUBLIC
    );
    return { success: true }; // Retorna un objeto indicando éxito
  } catch (error) {
    console.error('Error by sending the email:', error);
    throw new Error('There was a problem trying to send this email.'); // Lanza un error para manejarlo en el componente
  }
};
