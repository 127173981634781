import React from "react";
import ReleasesHomeCard from "./ReleasesHomeCard";
import { useSelector } from "react-redux";

const ReleasesList = () => {
  const { items: releases, loading, error } = useSelector((state) => state.releases);

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>Error: {error}</p>;
  }

  const filteredReleases = releases.filter(release => release.released !== 0);

  return (
    <div className="relative lg:mt-72 pt-64 lg:pt-16 pb-20 relative mx-auto max-full bg-[#0f0f0f]">
      <div className="text-center text-2xl tracking-wider text-white mb-36">
        Releases
      </div>
      <div className="flex flex-col h-full sm:flex-row gap-6 px-4 lg:px-6">
        {filteredReleases.map((release, key) => (
          <div key={key} className="flex sm:max-w-[calc(33.333%-1rem)]">
            <ReleasesHomeCard data={release} />
          </div>
        ))}
      </div>
      <div>
        <p className="text-white text-center text-2xl mt-32">
          Explore our full cataloge <a href="https://mmdiscos.bandcamp.com/" target="_blank" rel="noopener noreferrer" className="text-cyan-400">here</a>
        </p>
      </div>

    </div>
  );
};

export default ReleasesList;
