import { createSlice } from '@reduxjs/toolkit';

const releasesSlice = createSlice({
  name: 'releases',
  initialState: {
    items: [],
    loading: false,
    error: null,
    item: null,
    itemLoading: false,
    itemError: null,
  },
  reducers: {
    setLoading(state, action) {
      state.loading = action.payload;
    },
    setReleases(state, action) {
      state.items = action.payload;
    },
    setError(state, action) {
      state.error = action.payload;
    },
    setItemLoading(state, action) {
      state.itemLoading = action.payload;
    },
    setRelease(state, action) {
      state.item = action.payload;
    },
    setItemError(state, action) {
      state.itemError = action.payload;
    },
  },
});

export const { setLoading, setReleases, setError, setItemLoading, setRelease, setItemError } = releasesSlice.actions;
export default releasesSlice.reducer;
