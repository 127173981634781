import React, { useEffect, useState } from "react";
import { ReactComponent as LogoFlip } from '../../assets/images/AD Logo_Flip.svg';

function LogoBanner() {

  const [rotation, setRotation ] = useState(0);

  useEffect (() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;
      const newRotation = scrollY * 0.5;
      setRotation(newRotation);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  
  return (
    <div className="flex justify-center items-center h-screen pb-20 pt-20"
         style={{  backgroundColor: '#0f0f0f', perspective: '1000px' }}>
      <LogoFlip className="w-64 h-auto"
      style={{ transform: `rotateY(${rotation}deg)` }}
      />
      
    </div>
  );
}

export default LogoBanner;
