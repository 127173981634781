import React from "react"
import { ReactComponent as Logo} from '../../assets/images/MM_Planet_Sticker.svg'

function About() {
  return (
    <div id="about" className="flex justify-center items-center py-12 text-white mx-auto w-full max-w-screen-lg lg:max-w-7xl px-4 lg:px-16" 
                    >
      <div className="text-xl lg:text-3xl text-center px-12 lg:px-24 py-12 lg:py-16 w-full"
          style={{ backgroundColor: '#2d2d2d' }}>
        <div className="mb-20">WE ARE</div>
        
        <div className="mb-8 leading-6 lg:leading-none">
          A SUB-LABEL OF MM DISCOS.
        </div>

        <div className="mb-2 leading-6 lg:leading-none">
          <span className="block -mx-2 lg:mx-0">A DIGITAL FIRST MUSIC LABEL FOCUSED ON EXPLORING IN A</span>
          <span className="block">BROADWAY THE WORLD OF ACID MUSIC</span>
        </div>

        <div className="text-5xl lg:text-6xl mb-8 tracking-[-0.075em]">··</div>
        <div className="flex justify-center intems-center">
           <Logo />
        </div>
      </div>
    </div>
  );
}

export default About;
