import React from 'react'
import { Outlet } from "react-router-dom";
import { useSelector } from 'react-redux';
import Navbar from '../components/layout/navbar';
import Footer from '../components/layout/footer';

import MenuReleases from '../components/layout/dropdownReleases'
import ContactForm from '../components/layout/dropdownContact'

function DefaultLayout() {

  const { playerDisplay } = useSelector(state => state.player);

  return (
    <>
      <div className={`App bg-[#0f0f0f] ${playerDisplay ? 'pb-28 lg:pb-20' : 'pb-0'} transition-pb duration-500 ease-in-out`}>
        <Navbar />
        <ContactForm />
        <MenuReleases />
        <main className=''>
          <Outlet />
        </main>
        <Footer />
      </div>
    </>
  )
}

export default DefaultLayout;