import React from 'react';
import Motion_Header from "../../assets/motion/MMAD Motion Header 6.mp4";

const HeaderBanner = () => {
  return (
    <div className="relative w-full h-screen overflow-hidden">
      <video
        src={Motion_Header}
        autoPlay
        loop
        muted
        className="absolute inset-0 w-full h-full object-cover"
        playsInline
      />
    </div>
  );
};

export default HeaderBanner;
