import { Route, Routes, useLocation } from 'react-router-dom'
import DefaultLayout from "./layouts/Default.layout";
import Home from './views/Home';
import Release from "./views/Release";
import Error404 from './views/Error404'

import { AnimatePresence, motion } from 'framer-motion';

function AnimatedRoutes() {
  const location = useLocation()

  const HomeToRelease = {
    initial: { x: "-100%" },
    animate: { x: 0, transition: { duration: .5, ease: "easeOut" } },
    exit: { x: "-100%", transition: { duration: .5, ease: "easeIn" } },
  };
  const ReleaseToHome = {
    initial: { x: "100%" },
    animate: { x: 0, transition: { duration: .5, ease: "easeOut" } },
    exit: { x: "100%", transition: { duration: .5, ease: "easeIn" } },
  };


  
  return(
    <AnimatePresence mode="wait">
      <Routes location={location} key={location.pathname}>
        <Route
          path="*"
          element={<Error404 />}
        />
        <Route path="/" element={<DefaultLayout />}>
          <Route index element={
            <motion.div
              initial="initial"
              animate="animate"
              exit="exit"
              variants={HomeToRelease}
            >
              <Home />
            </motion.div>
          } />
          <Route path="release/:id" element={
                <motion.div
                  initial="initial"
                  animate="animate"
                  exit="exit"
                  variants={ReleaseToHome}
                >
                  <Release />
                </motion.div>
              } />
        </Route>
      </Routes>
    </AnimatePresence>
  )
}

export default AnimatedRoutes