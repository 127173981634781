import React from 'react';
import { Link } from 'react-router-dom';

function Error404() {
  return (
    <div className="min-h-screen flex flex-col justify-center items-center bg-[#0f0f0f] text-white text-center">
      <h1 className="text-8xl mb-8">ERROR 404</h1>
      <Link to="/" className="text-2xl text-[#59d4ef]">
        Back to Home
      </Link>
    </div>
  );
}

export default Error404;
