import React from "react";

const CustomAlert = ({ message, onConfirm }) => {
  return (
    <div className="fixed top-12 lg:top-12 w-full lg:right-5 z-50 flex justify-end">
      <div className="bg-white py-2 px-5 w-full lg:w-auto flex items-center space-x-6">
        <p className="text-black text-xl">{message}</p>
        <button
          onClick={onConfirm}
          className="text-black text-4xl hover:text-gray-700 ml-6"
        >
          &#x2715;
        </button>
      </div>
    </div>
  );
};

export default CustomAlert;
