import { configureStore } from '@reduxjs/toolkit';
import releasesReducer from '../features/releases/releasesSlice';

import playerReducer from '../features/audioplayer/playerSlice';
import layoutReducer from '../features/layout/layoutSlice';

export const store = configureStore({
  reducer: {
    releases: releasesReducer,
    player: playerReducer,
    layout: layoutReducer,
  },
});
