import React, { useRef } from 'react'
import { BrowserRouter as Router } from 'react-router-dom';
import AnimatedRoutes from './AnimatedRoutes';
import AudioPlayer from '../src/components/audioplayer/audioplayer';

function App() {
  const audioRef = useRef(null);
  return (
    <>
    <Router>
      <AnimatedRoutes />
    </Router>
    <AudioPlayer audioRef={audioRef} />
    </>
  )
}

export default App;
