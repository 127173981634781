import React, { useEffect } from 'react'
import About from '../components/about/About'
import Promo from '../components/promo_release/Promo'
import BannerFooter from '../components/banner_footer/BannerFooter'
import BandcampBanner from '../components/bandcamp_banner/BandcampBanner'
import ReleasesHomeList from '../components/releases_home/ReleasesHomeList'
import HeaderBanner from '../components/motion_banners/HeaderBanner'
import { useLocation } from 'react-router-dom';
import LogoBanner from '../components/logo_banner/LogoBanner'



function Home() {
  const { pathname, hash } = useLocation();

  useEffect(() => {
    if (hash) {
      // Si hay un hash, desplazarse suavemente a esa sección
      const element = document.getElementById(hash.substring(1));
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    } else {
      // Si no hay hash, desplazarse al principio de la página
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, [pathname, hash]);

  return (
    <div className='bg-[#0f0f0f]'>
      <HeaderBanner/>
      <BandcampBanner />
      <Promo />
      <ReleasesHomeList />
      <LogoBanner />
      <About />
      <BannerFooter />
    </div>
  )
}

export default Home