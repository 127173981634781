  import React, { useEffect, useRef } from 'react';
  import { useSelector, useDispatch } from 'react-redux';
  import { setCurrentIndex, setIsPlaying, setAudioProgress } from '../../features/audioplayer/playerSlice';
  import { getFirebaseDownloadURL } from './audioplayer-actions'

  const AudioPlayer = () => {
    const dispatch = useDispatch();
    const audioRef = useRef(null);
    const { trackList, currentIndex, isPlaying, audioProgress, playerDisplay } = useSelector(state => state.player);

    const togglePlayback = () => {
      if (isPlaying) {
        audioRef.current.pause();
      } else {
        audioRef.current.play();
      }
      dispatch(setIsPlaying(!isPlaying));
    };

    const previousTrack = () => {
      const newIndex = currentIndex > 0 ? currentIndex - 1 : trackList.length - 1;
      dispatch(setCurrentIndex(newIndex));
      dispatch(setIsPlaying(true));
    };

    const nextTrack = () => {
      const newIndex = currentIndex < trackList.length - 1 ? currentIndex + 1 : 0;
      dispatch(setCurrentIndex(newIndex));
      dispatch(setIsPlaying(true));
    };

    const updateAudioProgress = () => {
      const duration = audioRef.current.duration;
      const currentTime = audioRef.current.currentTime;
      dispatch(setAudioProgress((currentTime / duration) * 100));
    };

    const handleProgress = (e) => {
      const width = e.target.clientWidth;
      const clickX = e.nativeEvent.offsetX;
      const duration = audioRef.current.duration;

      audioRef.current.currentTime = (clickX / width) * duration;
    };

    useEffect(() => {
      if (trackList && audioRef.current && currentIndex !== null) {
        const audioElement = audioRef.current;
        
        getFirebaseDownloadURL(trackList[currentIndex].audio)
          .then(downloadUrl => {
            if (audioElement.src !== downloadUrl) {
              audioElement.src = downloadUrl;
              audioElement.load(); // Forzar la recarga del audio
              
              if (isPlaying) {
                audioElement.play().catch(error => {
                  console.log("Playback error:", error);
                });
              }
            }
          })
          .catch(err => {
            console.error("Error fetching download URL:", err);
          });
      }
      // eslint-disable-next-line
    }, [currentIndex, trackList]);

    

    useEffect(() => {
      if (audioRef.current) {
        if (!isPlaying) {
          audioRef.current.pause();
        } else {
          audioRef.current.play().catch(error => {
            console.log("Playback error:", error);
          });
        }
      }
    }, [isPlaying]);

    return (
      <div className={`audio-player bg-green bg-opacity-50 backdrop-blur-md mix-blend-light ${playerDisplay ? 'bottom-0' : '-bottom-24'} transition-all duration-500 ease-in-out fixed z-40 flex flex-col lg:flex-row gap-1 lg:gap-6 justify-between items-center w-full px-4 text-white border-t border-b leading-tight`}>
        {trackList && playerDisplay && (
          <>
            <div className="song-details flex w-full lg:w-3/12 text-xl mt-2 lg:mt-0 justify-center lg:justify-start items-center">
              <span className="mr-2">Now Playing: </span>
              <div className="relative overflow-hidden whitespace-nowrap flex-1">
                <div className="inline-block animate-marquee">
                  {currentIndex !== null && (
                    <> {trackList[currentIndex].cat_number} | {trackList[currentIndex].artist} - {trackList[currentIndex].track_name}</>
                  )}
                </div>
              </div>
            </div>
            <div
              className="progress-bar h-1 -mb-1 lg:mt-0 w-full lg:w-4/12"
              onClick={handleProgress}
              style={{ background: `linear-gradient(to right, #FFFFFF ${audioProgress}%, rgba(255,255,255, .5) 0)` }}
            >
              <div className="progress" style={{ width: `${audioProgress}%` }}></div>
            </div>
            <div className="controls mt-1 lg:-mt-1 -mb-2 lg:-mb-3 flex flex-row justify-between lg:justify-between w-full lg:w-4/12 text-5xl lg:text-6xl space-x-2 lg:space-x-4">
              <button onClick={previousTrack}>Previous</button>
              <button onClick={togglePlayback}>
                {isPlaying ? 'Pause' : 'Play'}
              </button>
              <button onClick={nextTrack}>Next</button>
            </div>
            <audio
              ref={audioRef}
              onTimeUpdate={updateAudioProgress}
              onEnded={nextTrack}
            />
          </>
        )}
      </div>
    );

  }

  export default AudioPlayer;
