import { collection, query, getDocs, doc, getDoc } from 'firebase/firestore';
import { db } from '../../firebaseConfig';
import { 
  setLoading,
  setReleases,
  setError,
  setItemLoading,
  setRelease,
  setItemError,

} from './releasesSlice';

export const fetchReleases = () => async dispatch => {
  dispatch(setLoading(true));
  try {
    const q = query(collection(db, 'releases'));
    const querySnapshot = await getDocs(q);
    const releases = querySnapshot.docs.map(doc => {
      const data = doc.data();
      return {
        id: doc.id,
        ...data,
        release_date: data.release_date ? data.release_date.toDate().toISOString() : null,
      };
    });
    dispatch(setReleases(releases));
  } catch (error) {
    dispatch(setError(error.message));
  } finally {
    dispatch(setLoading(false));
  }
};

export const fetchReleaseById = (id) => async dispatch => {
  dispatch(setItemLoading(true));
  try {
    const docRef = doc(db, 'releases', id);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      const data = docSnap.data();
      dispatch(setRelease({
        id: docSnap.id,
        ...data,
        release_date: data.release_date ? data.release_date.toDate().toISOString() : null,
      }));
    } else {
      throw new Error('Release not found');
    }
  } catch (error) {
    dispatch(setItemError(error.message));
  } finally {
    dispatch(setItemLoading(false));
  }
};
