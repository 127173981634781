import { useState, useEffect } from 'react';
import {
  Popover,
  PopoverGroup,
  PopoverPanel,
  PopoverButton,
} from '@headlessui/react';
import { fetchReleases } from '../../features/releases/releasesActions';
import { Link, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { setContactDisplay, setMenuReleasestDisplay } from '../../features/layout/layoutSlice';
import { ReactComponent as AcidDefault } from '../../assets/images/AcidSmile Icon_Default.svg';
import { ReactComponent as AcidHover } from '../../assets/images/AcidSmile Icon_Hover.svg';

function Navbar() {
  const dispatch = useDispatch();
  const location = useLocation();
  
  const [currentTime, setCurrentTime] = useState('');

  const { items: releases, loading, error } = useSelector(state => state.releases);
  const { menuReleasestDisplay: releaseVisibility, contactDisplay: contactVisibility } = useSelector(state => state.layout);

  const handleMenuReleases = () => {
    dispatch(setMenuReleasestDisplay(!releaseVisibility));
    dispatch(setContactDisplay(false));
  }

  const handleContact = () => {
    dispatch(setContactDisplay(!contactVisibility));
    dispatch(setMenuReleasestDisplay(false));
  }

  useEffect(() => {
    if (releases.length === 0) {
      dispatch(fetchReleases());
    }
  // eslint-disable-next-line
  }, [dispatch]);

  useEffect(() => {
    const updateClock = () => {
      const now = new Date();
      const hours = now.getHours().toString().padStart(2, '0');
      const minutes = now.getMinutes().toString().padStart(2, '0');
      const seconds = now.getSeconds().toString().padStart(2, '0');
      setCurrentTime(`${hours}:${minutes}:${seconds}`);
    };

    updateClock();

    const interval = setInterval(updateClock, 1000);

    return () => clearInterval(interval);
  }, []);

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>Error: {error}</p>;
  }

  return (
    <header className="z-40 top-0 fixed w-full mix-blend-difference">
      <nav aria-label="Global" className="relative sm:text-lg lg:text-xl mx-auto flex w-full items-center justify-between py-6">
        <PopoverGroup className="w-full flex justify-between lg:gap-x-12">
          <Popover>
            <PopoverButton
              className="flex items-center gap-x-1 text-white outline-0 mx-4 pl-0 lg:pl-2"
              onClick={handleMenuReleases}
            >
              Releases
            </PopoverButton>

            <PopoverPanel
              transition
              className="absolute left-0 top-full z-10 mt-3 w-screen bg-[#0f0f0f] text-white shadow-lg ring-1 ring-gray-900/5 transition data-[closed]:translate-y-1 data-[closed]:opacity-0 data-[enter]:duration-200 data-[leave]:duration-150 data-[enter]:ease-out data-[leave]:ease-in"
            >
            </PopoverPanel>
          </Popover>
          <Link to="/#about" className="text-white mx-6 hidden lg:block">
            About
          </Link>
          <Link
            to="/"
            className="leading-6 text-white mx-6 pl-0 lg:pl-6"
            onClick={() => {
              if (location.pathname === '/') {
                window.scrollTo({ top: 0, behavior: 'smooth' });
              } else {
                window.scrollTo({ top: 0 });
              }
            }}
          >
            Acid Discos
          </Link>
          <Popover>
            <PopoverButton
              onClick={handleContact}
              className="flex items-center gap-x-1 text-white outline-0 mx-4"
            >
              Contact
            </PopoverButton>
          </Popover>
          <div className="flex items-center leading-6 text-white mx-5 hidden lg:flex">
            {/* Icono con hover */}
            <div className="relative mr-2 flex-shrink-0">
              <AcidDefault className="transition-opacity duration-300 opacity-100 hover:opacity-0" />
              <AcidHover className="absolute top-0 left-0 transition-opacity duration-300 opacity-0 hover:opacity-100" />
            </div>
            <span className='inline-block w-[7.5ch] text-center'>{currentTime}</span>
          </div>
        </PopoverGroup>
      </nav>
    </header>
  );
}

export default Navbar;
