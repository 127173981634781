import React, { useState, useEffect } from 'react';
import { storage } from '../../firebaseConfig';
import { ref as storageRef, getDownloadURL } from 'firebase/storage';

function UrlFirebase({ url, className }) {
  const [source, setSource] = useState(null);
  const [error, setError] = useState(null);


  useEffect(() => {
    const fetchUrl = async () => {

      try {
        const imageRef = storageRef(storage, url);
        const downloadUrl = await getDownloadURL(imageRef);
        setSource(downloadUrl);
      } catch (err) {
        setError(err.message);
      }
    };

    fetchUrl();
  }, [url]);

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <>
      {source ? (
        <img src={source} className={className} alt="Firebase file" />
      ) : (
        <div>Loading...</div>
      )}
    </>
  );
}

export default UrlFirebase;
