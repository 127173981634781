import React, { useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setContactDisplay, setMenuReleasestDisplay } from '../../features/layout/layoutSlice';
import { setPlayerReleaseId, setTrackList, setCurrentIndex, setIsPlaying, setPlayerDisplay } from '../../features/audioplayer/playerSlice';

const MenuReleases = () => {
  const { items: releases, loading, error } = useSelector(state => state.releases);
  const { menuReleasestDisplay: visibility } = useSelector(state => state.layout);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const ref = useRef(null);

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target) && event.srcElement.innerText !== 'Releases') {
      dispatch(setMenuReleasestDisplay(false));
    }
  };

  const goToRelease = (id) => {
    dispatch(setContactDisplay(false));
    dispatch(setMenuReleasestDisplay(false));
    navigate(`/release/${id}`)

  }

  const playRelease = (id, tracklist) => {
    dispatch(setPlayerReleaseId(id));
    dispatch(setTrackList(tracklist));
    dispatch(setPlayerDisplay(true));
    dispatch(setCurrentIndex(0));
    dispatch(setIsPlaying(true));
  }

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  // eslint-disable-next-line
  }, []);

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>Error: {error}</p>;
  }

  const filteredReleases = releases.filter(release => release.released === 1);

  return (
    <div ref={ref} className={`${visibility ? 'block' : 'hidden'} bg-[#0f0f0f] z-40 fixed top-16 w-full text-white -mt-3`}>
      {filteredReleases.map(release => (
        <div key={release.id} className="relative w-full border border-white border-0 border-t flex items-center">
          <div className="flex w-full items-center pl-4 lg:pl-6">
            <button 
              onClick={() => goToRelease(release.id)} 
              className="basis-4/6 lg:basis-1/4 sm:text-lg lg:text-xl text-left flex items-center py-2"
            >
              <span className="lg:mr-1">
                {release.cat_number}
              </span>
              {/* Show artist and title only in lg devices */}
              <div className="hidden lg:inline-block overflow-hidden whitespace-nowrap relative w-[200px]">
                <span className="inline-block animate-marquee">
                  {release.artist} - {release.title}
                </span>
              </div>
            </button>
            <button
              onClick={() => playRelease(release.id, release.tracklist)}
              className="text-white text-right lg:text-left -mb-6 lg:-mb-3 -mt-4 lg:-mt-1 mr-5 lg:mr-28 text-[55px] lg:text-6xl basis-2/6 lg:basis-3/4 whitespace-nowrap">
              Play music.
            </button>
          </div>
        </div>
      ))}
      <div className="w-full border border-white border-0 border-y pl-4 lg:pl-6 sm:py-none py-2 lg:py-2 sm:text-lg lg:text-xl">
        Coming Soon...
      </div>
    </div>
  );
  
};

export default MenuReleases;
