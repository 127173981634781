import React, { useRef, useState, useEffect } from 'react';
import { sendEmail } from '../contact/emailService';
import { useDispatch, useSelector } from 'react-redux';
import { setContactDisplay } from '../../features/layout/layoutSlice';
import CustomAlert from '../contact/CustomAlert';

const ContactForm = () => {
  const ref = useRef(null)
  const dispatch = useDispatch()
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [object, setObject] = useState('');
  const [subject, setSubject] = useState('');
  const [url, setUrl] = useState('');
  const [alertMessage, setAlertMessage] = useState(''); // Estado para el mensaje de alerta
  const [showAlert, setShowAlert] = useState(false); // Estado para mostrar u ocultar la alerta
  const { contactDisplay: visibility } = useSelector(state => state.layout);

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target) && event.srcElement.innerText !== 'Contact') {
      dispatch(setContactDisplay(false));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    try {
      await sendEmail(name, email, object, subject, url);
      setAlertMessage('Your email has been sent successfully!');
      dispatch(setContactDisplay(false));
    } catch {
      setAlertMessage('There was a problem trying to send this email.');
    }
    setShowAlert(true);
    dispatch(setContactDisplay(false));

    setName('');
    setEmail('');
    setObject('');
    setSubject('');
    setUrl('');
  };

  const handleAlertConfirm = () => {
    setShowAlert(false); // Hide alert
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  // eslint-disable-next-line
  }, []);


  return (
    <>
      <form ref={ref} className={`${ visibility ? 'block' : 'hidden' } bg-[#0f0f0f] z-40 fixed top-16 space-y w-full text-xl -mt-3`} onSubmit={handleSubmit}>
        <div className='flex items-center border border-white border-0 border-t py-2'>
          <label className='hidden lg:w-1/4 lg:block text-white lg:pl-6'>Name</label>
          <input 
            type="text" 
            value={name} 
            onChange={(e) => setName(e.target.value)}
            className="w-full pl-4 lg:pl-14 block bg-[#0f0f0f] text-white placeholder-[#4c4c4c] focus:outline-none focus:ring-white focus:border-white"
            placeholder="Your Name"
            required 
          />
        </div>
        <div className='flex items-center border border-white border-0 border-t py-2'>
          <label className="hidden lg:w-1/4 lg:block text-white pl-6">Email</label>
          <input 
            type="email" 
            value={email} 
            onChange={(e) => setEmail(e.target.value)}
            className="w-full pl-4 lg:pl-14 block bg-[#0f0f0f] text-white placeholder-[#4c4c4c] focus:outline-none focus:ring-white focus:border-white"
            placeholder="Your Email" 
            required 
          />
        </div>
        
        <div className='flex items-center border border-white border-0 border-t'>
          <label className="hidden lg:w-1/4 lg:block text-white py-2 pl-6">Object</label>
          <div className="flex space-x-4 w-full pl-0 lg:pl-14 py-2">
            <div className="flex items-center">
              <input 
                type="radio" 
                id="inquire"
                name="object"
                value="General Inquire" 
                checked={object === 'General Inquire'} 
                onChange={(e) => setObject(e.target.value)}
                className="hidden peer"
                required 
              />
              <label 
                htmlFor="inquire"
                className={`flex items-center cursor-pointer text-[#4c4c4c] peer-checked:text-white sm:mr-2 lg:mr-24 pl-4 lg:pl-0`}>
                <span className={`h-4 w-4 mr-2 border-2 rounded-full flex items-center justify-center ${object === 'General Inquire' ? 'border-white bg-white' : 'border-[#4c4c4c] '}`}>
                  {object === 'General Inquire' && (
                    <span className="bg-[#0f0f0f]"></span>
                  )}
                </span>
                General Inquire
              </label>
            </div>
            <div className="flex items-center">
              <input 
                type="radio" 
                id="demos"
                name="object"
                value="Demos" 
                checked={object === 'Demos'} 
                onChange={(e) => setObject(e.target.value)}
                className="hidden peer"
                required 
              />
              <label 
                htmlFor="demos"
                className={`flex items-center cursor-pointer text-[#4c4c4c] peer-checked:text-white ml-12`}>
                <span className={`h-4 w-4 mr-2 border-2 rounded-full flex items-center justify-center ${object === 'Demos' ? 'border-white bg-white' : 'border-[#4c4c4c] bg-[#0f0f0f]'}`}>
                  {object === 'Demos' && (
                    <span className="bg-[#0f0f0f]"></span>
                  )}
                </span>
                Send Demos
              </label>
            </div>
          </div>
        </div>

        <div className='flex items-center border border-white border-0 border-t'>
          <label className="hidden lg:w-1/4 lg:block text-white py-2 pl-6">Subject</label>
          <input
            type='text' 
            value={subject} 
            onChange={(e) => setSubject(e.target.value)}
            className="w-full pl-4 lg:pl-14 block py-2 bg-[#0f0f0f] text-white placeholder-[#4c4c4c] focus:outline-none focus:ring-white focus:border-white"
            placeholder="Type your message here"
            required 
          />
        </div>

        {object === 'Demos' && (
          <div className='flex items-center border border-white border-0 border-t'>
            <label className="hidden lg:w-1/4 lg:block text-white py-2 pl-6">URL</label>
            <input 
              type="url" 
              value={url} 
              onChange={(e) => setUrl(e.target.value)}
              className="w-full pl-4 lg:pl-14 block py-2 bg-[#0f0f0f] text-white placeholder-[#4c4c4c] focus:outline-none focus:ring-white focus:border-white"
              placeholder="Your URL" 
            />
          </div>
        )}

        <div className="flex items-center">
          <button
            type="submit"
            className="w-full bg-white text-black border border-white border-0 border-y py-2 pl-4 lg:pl-6 text-left"
          >
            Submit
          </button>
        </div>

      </form>
      {showAlert && <CustomAlert message={alertMessage} onConfirm={handleAlertConfirm} />}
    </>
  );
};

export default ContactForm;