// import React, { useEffect, useState } from "react";
// import { doc, getDoc } from "firebase/firestore";
// import { db } from "../../firebaseConfig";


import React from "react";
import Promo_Banner_Left_Final2 from "../../assets/motion/MMAD Release Banner Left Motion Final2.mp4"
import Promo_Banner_Right2 from "../../assets/motion/MMAD Release Banner Right Motion.mp4"

function Promo() {
  return (
    <div className="flex flex-col lg:flex-row justify-center items-center lg:mx-6">
      <div className="flex-shrink-0 lg:w-[50%] bg-[#0f0f0f]">
        <video src={Promo_Banner_Left_Final2} 
               alt="Promo Banner Motion Left" 
               className="w-full h-auto"
               autoPlay
               loop
               muted
               playsInline
               />
      </div>
      
      <div className="flex-shrink-0 lg:w-[50%]">
        <video src={Promo_Banner_Right2} 
               alt="Promo Banner Motion Right" 
               className="w-full h-auto"
               autoPlay
               loop
               muted
               playsInline
               />
      </div>
    </div>
  );
}

export default Promo;

/*
function Promo() {
  const [banners, setBanners] = useState({ bannerLeft: "", bannerRight: ""});

  useEffect(() => {
    const fetchBanners = async () => {
      try {
        console.log("Fetching banners from Firestore...");
        const docRef = doc(db, "banner_promo", "p4GL5ln4I7EFVx5OEzAe");
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          console.log("Document data:", docSnap.data());
          setBanners({
            bannerLeft: docSnap.data().banner_left,
            bannerRight: docSnap.data().banner_right,
          });
        } else {
          console.log("No such document")
        }
      } catch (error) {
        console.error("Error fetching document: ", error);
      }
    };
    fetchBanners();
  }, []);

  return (
    <div className="flex flex-col lg:flex-row justify-center items-center lg:mx-6">
      <div className="flex-shrink-0 lg:w-[50%] bg-[#0f0f0f]">
        <video src={banners.bannerLeft} 
               alt="Promo Banner Motion Left" 
               className="w-full h-auto"
               autoPlay
               loop
               muted
               playsInline
               />
      </div>
      
      <div className="flex-shrink-0 lg:w-[50%]">
        <video src={banners.bannerRight} 
               alt="Promo Banner Motion Right"
               className="w-full h-auto"
               autoPlay
               loop
               muted
               playsInline
               />
      </div>
    </div>
  );
}

export default Promo;
*/
