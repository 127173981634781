import React, { useEffect, useState } from "react";
import { getDownloadURL, ref } from "firebase/storage";
import { storage } from "../../firebaseConfig";

function BannerFooter() {
  const [images, setImages] = useState({
    bannerFooterColourImage: null,
    bannerFooter01: null,
    bannerFooter02: null,
    bannerFooter03: null,
  });

  useEffect(() => {
    
    const fetchImages = async () => {
      try {
        const bannerFooterColourRef = ref(storage, 'Acid Discos Images/Footer Banner Home Visual.png');
        const bannerFooter01Ref = ref(storage, 'Acid Discos Images/Footer Banner Logo.png');
        const bannerFooter02Ref = ref(storage, 'Acid Discos Images/Footer banner Buy Records.png');
        const bannerFooter03Ref = ref(storage, 'Acid Discos Images/Footer banner WavMp3.png');

        const bannerFooterColourImage = await getDownloadURL(bannerFooterColourRef);
        const bannerFooter01 = await getDownloadURL(bannerFooter01Ref);
        const bannerFooter02 = await getDownloadURL(bannerFooter02Ref);
        const bannerFooter03 = await getDownloadURL(bannerFooter03Ref);

        setImages({
          bannerFooterColourImage,
          bannerFooter01,
          bannerFooter02,
          bannerFooter03,
        });
      } catch (error) {
        console.error("Error fetching images from Firebase Storage", error);
      }
    };

    fetchImages();
  }, []);

  return (
    <div className="w-full -mt-64 lg:mt-0 mb-24">
      <div className="w-full mb-4 mt-64 px-4 lg:px-6">
        <div className="relative w-full h-[304px]">
          {images.bannerFooterColourImage && (
            <img src={images.bannerFooterColourImage} alt="Promo Banner 01" className="absolute inset-0 w-full h-full object-cover" />
          )}
        </div>
      </div>
      <div className="flex flex-col lg:flex-row justify-center items-center gap-4 px-4 lg:px-6">
        <div className="">
          {images.bannerFooter01 && (
            <img src={images.bannerFooter01} alt="Promo Banner 01" className="w-full" />
          )}
        </div>
        <div className="">
          {images.bannerFooter02 && (
            <img src={images.bannerFooter02} alt="Promo Banner 02" className="w-full" />
          )}
        </div>
        <div className="">
          {images.bannerFooter03 && (
            <img src={images.bannerFooter03} alt="Promo Banner 03" className="w-full" />
          )}
        </div>
      </div>
    </div>
  );
}

export default BannerFooter;
